import React from "react";
import "./NotFoundPage.scss";
import notFound from "../../Assets/Icons/404.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function NotFoundPage() {
    return (
        <>
            <Helmet>
                <title>Page Not Found | RezBuddy</title>
                <meta
                    name="description"
                    content="Oops! The page you're looking for doesn't exist. Navigate back to RezBuddy to find Indigenous businesses, tax-free gas stations, and more across Canada."
                />
                <meta
                    name="keywords"
                    content="404 page not found, broken link, page missing, RezBuddy, Indigenous businesses, tax-free gas stations, Canada"
                />
                <meta name="author" content="RezBuddy.ca" />
                <link rel="canonical" href="https://www.rezbuddy.ca/404" />
            </Helmet>
            <main className="not-found main">
                <h3>404</h3>
                <h1>Page Not Found</h1>
                <img className="not-found__image" src={notFound} alt="404 page not found" />
                <div className="not-found__buttons">
                    <Link to="/gas">
                        <button className="not-found__button">First Nation Gas Stations</button>
                    </Link>
                    <Link to="/">
                        <button className="not-found__button">Home</button>
                    </Link>
                    <Link to="/indigenous-businesses">
                        <button className="not-found__button">
                            {" "}
                            Indigenous Business Directory
                        </button>
                    </Link>
                </div>
            </main>
        </>
    );
}

export default NotFoundPage;
