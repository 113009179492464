import axios from "axios";
import React, { useState, useRef } from "react";
import "./SearchBar.scss";
import { useNavigate } from "react-router-dom";

function SearchBar({ searchTerm, setSearchTerm, results, setResults }) {
    const [showDropdown, setShowDropdown] = useState(false);
    const searchRef = useRef(null);
    const navigate = useNavigate();

    const fetchBusinesses = async (term) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/business/search/${term}`
            );
            setResults(response.data || []);
            setShowDropdown(true);
        } catch (error) {
            console.error("Error fetching data:", error);
            setResults([]);
        }
    };

    const handleChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        if (term) {
            fetchBusinesses(term);
        } else {
            setResults([]);
            setShowDropdown(false);
        }
    };

    const handleSelect = (business) => {
        setShowDropdown(false);
        navigate(`/indigenous-businesses/${business.id}`);
    };

    const handleExit = () => {
        setShowDropdown(false);
    };

    return (
        <section className="bp-form-container" ref={searchRef}>
            <form className="bp-search-form" onSubmit={(e) => e.preventDefault()}>
                <label htmlFor="search-input" className="bp-search-form__label">
                    Search for First Nation Businesses:
                </label>
                <input
                    id="search-input"
                    type="text"
                    value={searchTerm}
                    onChange={handleChange}
                    placeholder="Search by name or city"
                    className="bp-search-form__input"
                />
                {showDropdown && results.length > 0 && (
                    <ul className="bp-search-dropdown">
                        {results.map((business) => (
                            <li
                                key={business.id}
                                className="bp-search-dropdown__item"
                                onClick={() => handleSelect(business)}
                            >
                                {business.company_operating_name}
                            </li>
                        ))}
                        <li className="bp-search-dropdown__exit" onClick={handleExit}>
                            exit
                        </li>
                    </ul>
                )}
            </form>
        </section>
    );
}

export default SearchBar;
