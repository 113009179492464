import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Details.scss";
import axios from "axios";
import { Helmet } from "react-helmet-async";

function Details() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchBusinessById(id);
        }
    }, [id]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const fetchBusinessById = async (id) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`http://localhost:8080/api/business/${id}`);
            setResults(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setResults(null);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!results) {
        return <p>No business details found.</p>;
    }

    return (
        <>
            <Helmet>
                <title>
                    Indigenous Business Details | Find Information on First Nation Businesses
                </title>
                <meta
                    name="description"
                    content="Find detailed information on First Nation businesses, including their phone number, name, website, and a brief description. Get easy access to Indigenous businesses across Canada."
                />
                <meta
                    name="keywords"
                    content="Indigenous businesses, First Nation businesses, business details, phone numbers, business websites, Indigenous communities, Canada, support Indigenous businesses"
                />
                <meta name="author" content="RezBuddy.ca" />

                <meta
                    property="og:title"
                    content="Indigenous Business Details | Find Information on First Nation Businesses"
                />
                <meta
                    property="og:description"
                    content="Get comprehensive details about First Nation businesses, including their phone number, name, website, and a brief description."
                />
                <meta
                    property="og:url"
                    content="https://www.rezbuddy.ca/indigenous-businesses/:id"
                />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary" />
                <meta
                    name="twitter:title"
                    content="Indigenous Business Details | Find Information on First Nation Businesses"
                />
                <meta
                    name="twitter:description"
                    content="Explore First Nation businesses and get detailed information such as phone numbers, websites, and descriptions."
                />

                <link rel="canonical" href="https://www.rezbuddy.ca/indigenous-businesses/:id" />
            </Helmet>
            <main className="main">
                <button onClick={handleGoBack} className="business-details-back">
                    Back
                </button>
                <section key={results.id} className="business-card business-card--details">
                    <h3 className="business-card__name">Business Name:</h3>
                    <p className="business-card__body">{results.company_operating_name}</p>
                    <h3 className="business-card__location">Business Location:</h3>
                    <p className="business-card__body">
                        {results.city}, {results.province}
                    </p>
                    <h3 className="business-card__description">Business Description:</h3>
                    <p className="business-card__body">{results.company_description}</p>
                    <div>
                        {results.url ? (
                            <a href={results.url} target="_blank" rel="noopener noreferrer">
                                <p className="business-card__link">Website Link</p>
                            </a>
                        ) : (
                            <p className="business-card__error">No website available</p>
                        )}
                    </div>
                </section>
            </main>
        </>
    );
}

export default Details;
