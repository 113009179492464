import axios from "axios";
import React, { useState, useRef } from "react";
import "./BusinessSection.scss";
import retail from "../../Assets/Icons/retail.svg";
import food from "../../Assets/Icons/bbq.svg";
import services from "../../Assets/Icons/services.svg";
import tourism from "../../Assets/Icons/tourism.svg";
import { Link, useNavigate } from "react-router-dom";
import useIntersectionObserver from "../Hooks/IntersectionObserver";

function BusinessSection() {
    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const searchRef = useRef(null);
    const navigate = useNavigate();

    const [ref1, entries1] = useIntersectionObserver(0.3);
    const [ref2, entries2] = useIntersectionObserver(0.3);
    const [ref3, entries3] = useIntersectionObserver(0.3);
    const [ref4, entries4] = useIntersectionObserver(0.3);
    const [ref5, entries5] = useIntersectionObserver(0.3);

    const fetchBusinesses = async (term) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/business/search/${term}`
            );
            setResults(response.data || []);
            setShowDropdown(true);
        } catch (error) {
            console.error("Error fetching data:", error);
            setResults([]);
        }
    };

    const handleChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        if (term) {
            fetchBusinesses(term);
        } else {
            setResults([]);
            setShowDropdown(false);
        }
    };

    const handleSelect = (business) => {
        setShowDropdown(false);
        navigate(`/indigenous-businesses/${business.id}`);
    };

    const handleExit = () => {
        setShowDropdown(false);
    };

    return (
        <section className="business-container">
            <h2
                className={`business-container__header ${
                    entries1[0]?.isIntersecting ? "visible" : ""
                }`}
                ref={ref1}
            >
                First Nation Businesses
            </h2>
            <div className="businesse-cards-container">
                <Link
                    to={`/indigenous-businesses/category/retail`}
                    className={`businesse-card ${entries2[0]?.isIntersecting ? "visible" : ""}`}
                    ref={ref2}
                    loading="lazy"
                >
                    <img
                        className="businesse-card__img"
                        alt="first nation retail businesses"
                        src={retail}
                        loading="lazy"
                    />
                    <h3 className="businesse-card__header">Retail</h3>
                </Link>
                <Link
                    to={`/indigenous-businesses/category/food`}
                    className={`businesse-card ${entries3[0]?.isIntersecting ? "visible" : ""}`}
                    ref={ref3}
                >
                    <img
                        className="businesse-card__img"
                        alt="first nation food businesses"
                        src={food}
                        loading="lazy"
                    />
                    <h3 className="businesse-card__header">Food</h3>
                </Link>
                <Link
                    to={`/indigenous-businesses/category/services`}
                    className={`businesse-card ${entries4[0]?.isIntersecting ? "visible" : ""}`}
                    ref={ref4}
                >
                    <img
                        className="businesse-card__img"
                        alt="first nation services businesses"
                        src={services}
                        loading="lazy"
                    />
                    <h3 className="businesse-card__header">Services</h3>
                </Link>
                <Link
                    to={`/indigenous-businesses/category/tourism`}
                    className={`businesse-card ${entries5[0]?.isIntersecting ? "visible" : ""}`}
                    ref={ref5}
                >
                    <img
                        className="businesse-card__img"
                        alt="first nation tourism businesses"
                        src={tourism}
                        loading="lazy"
                    />
                    <h3 className="businesse-card__header">Tourism</h3>
                </Link>
            </div>
            <Link to="/indigenous-businesses">
                <h3 className="businesse-card__all">View all</h3>
            </Link>
            <form className="business-search-form" ref={searchRef}>
                <input
                    className="business-search-form__input"
                    type="text"
                    placeholder="Quick search.."
                    value={searchTerm}
                    onChange={handleChange}
                />
                <button type="submit" className="business-search-form__button">
                    Search
                </button>
            </form>
            {showDropdown && results.length > 0 && (
                <ul className="bp-search-dropdown">
                    {results.map((business) => (
                        <li
                            key={business.id}
                            className="bp-search-dropdown__item"
                            onClick={() => handleSelect(business)}
                        >
                            {business.company_operating_name}
                        </li>
                    ))}
                    <li className="bp-search-dropdown__exit" onClick={handleExit}>
                        exit
                    </li>
                </ul>
            )}
            <p className="business-container__subhead">
                Use this directory to find First Nation businesses across Canada.
            </p>
        </section>
    );
}

export default BusinessSection;
