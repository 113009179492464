import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet-async";
import "./ArticleFull.scss";

function FullArticle() {
    const location = useLocation();
    const { article } = location.state;
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const ScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const articleSnippet = article.full_article.split(" ").slice(0, 25).join(" ") + "...";

    const keywords = [
        article.title,
        "Indigenous businesses",
        "Indigenous business",
        "RezBuddy",
        "First Nations",
        "Indigenous economic development",
        "Indigenous-owned gas stations",
        "support Indigenous businesses",
        "sustainable travel",
        "Canadian road trips",
        "Indigenous tourism",
        "Indigenous blog",
        "Indigenous articles",
    ];

    return (
        <>
            <Helmet>
                <title>{article.title} | RezBuddy</title>
                <meta name="description" content={article.short_description} />
                <meta name="keywords" content={keywords.join(", ")} />
                <meta property="og:title" content={article.title} />
                <meta property="og:description" content={articleSnippet} />
                <meta property="og:image" content={article.image_url} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <main className="main">
                <button onClick={handleGoBack} className="business-details-back">
                    Back
                </button>
                <section className="article">
                    <img className="article__image" src={article.image_url} />
                    <h1>{article.title}</h1>
                    <h2 className="description">{article.short_description}</h2>
                    <ReactMarkdown className="full-article-content">
                        {article.full_article}
                    </ReactMarkdown>
                </section>
                <button onClick={ScrollToTop} className="business-details-back">
                    Scroll to top
                </button>
            </main>
        </>
    );
}

export default FullArticle;
