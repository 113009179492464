import "./Homepage.scss";
import Search from "../../Components/Search/Search";
import Hero from "../../Components/Hero/Hero";
import PriceCards from "../../Components/PriceCards/PriceCards";
import React, { useState } from "react";
import Mapbox from "../../Components/Mapbox/Mapbox";
import { APIProvider } from "@vis.gl/react-google-maps";
import { useAuth } from "../../Components/LoginContext/LoginContext";
import BusinessSection from "../../Components/BusinessSection/BusinessSection";
import HomeAbout from "../../Components/HomeAbout/HomeAbout";
import { Helmet } from "react-helmet-async";
import Disclaimer from "../../Components/Disclaimer/Disclaimer";

function Homepage() {
    const { isLoggedIn } = useAuth();
    const [showMapModal, setShowMapModal] = useState(false);

    return (
        <>
            <Helmet>
                <title>
                    RezBuddy: Find Tax-Free Gas Stations | Support Indigenous Businesses | RezBuddy
                </title>
                <meta
                    name="description"
                    content="Find tax-free gas and the cheapest gas prices near you while supporting Indigenous businesses. Use this First Nation fuel finder to locate the best gas stations and fuel prices across Canada. Find other first nation businesses across Canada using our indigenous business directory."
                />
                <meta
                    name="keywords"
                    content="tax-free gas, gas prices, Indigenous gas stations, First Nation fuel finder, cheapest gas, support Indigenous business, Canada, fuel finder"
                />
                <meta name="author" content="RezBuddy.ca" />

                <meta
                    property="og:title"
                    content="Find Tax-Free Gas Stations | Support Indigenous Businesses"
                />
                <meta
                    property="og:description"
                    content="Use RezBuddy to locate tax-free gas stations and find the cheapest gas prices near you, all while supporting Indigenous businesses. Find first nation businesses across Canada using our indigenous business directory"
                />
                <meta property="og:url" content="https://www.rezbuddy.ca" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary" />
                <meta
                    name="twitter:title"
                    content="Find Tax-Free Gas Stations | Support Indigenous Businesses"
                />
                <meta
                    name="twitter:description"
                    content="Find tax-free gas and the best fuel prices while supporting Indigenous businesses in Canada with RezBuddy's fuel finder tool and Indigenous business directory."
                />

                <link rel="canonical" href="https://rezbuddy.ca" />
            </Helmet>
            <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                libraries={["places", "visualization"]}
            >
                <main className="main">
                    <Search setShowMapModal={setShowMapModal} />
                    <Hero />
                    {!isLoggedIn && (
                        <div className="login-false">Login to save favourites here</div>
                    )}
                    <PriceCards />
                    <BusinessSection />
                    <HomeAbout />
                    {showMapModal && <Mapbox setShowMapModal={setShowMapModal} />}
                    <Disclaimer />
                </main>
            </APIProvider>
        </>
    );
}

export default Homepage;
