import React from "react";
import ContactForm from "../../Components/ContactForm/ContactForm";
import "./Contact.scss";
import { Helmet } from "react-helmet-async";

function Contact() {
    return (
        <>
            <Helmet>
                <title>Contact RezBuddy | Get in Touch for Inquiries and Support</title>
                <meta
                    name="description"
                    content="Get in touch with RezBuddy for any questions, feedback, or support regarding Indigenous businesses. Request updates, changes, or information on listed businesses across Canada."
                />
                <meta
                    name="keywords"
                    content="Contact RezBuddy, Indigenous businesses support, business inquiries, feedback, update business listings, RezBuddy contact"
                />
                <meta name="author" content="RezBuddy.ca" />

                <meta
                    property="og:title"
                    content="Contact RezBuddy | Get in Touch for Inquiries and Support"
                />
                <meta
                    property="og:description"
                    content="Contact RezBuddy for assistance with Indigenous business listings or to request changes. We're here to help!"
                />
                <meta property="og:url" content="https://www.rezbuddy.ca/contact-us" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:title"
                    content="Contact RezBuddy | Get in Touch for Inquiries and Support"
                />
                <meta
                    name="twitter:description"
                    content="Reach out to RezBuddy for questions or updates on Indigenous business listings across Canada."
                />

                <link rel="canonical" href="https://www.rezbuddy.ca/contact-us" />
            </Helmet>
            <main className="main main--contact">
                <ContactForm />
            </main>
        </>
    );
}

export default Contact;
