import "./Gas.scss";
import React, { useState } from "react";
import { useAuth } from "../../Components/LoginContext/LoginContext";
import Search from "../../Components/Search/Search";
import Hero from "../../Components/Hero/Hero";
import About from "../../Components/About/About";
import Mapbox from "../../Components/Mapbox/Mapbox";
import PriceCards from "../../Components/PriceCards/PriceCards";
import { APIProvider } from "@vis.gl/react-google-maps";
import { Helmet } from "react-helmet-async";

const libraries = ["places", "visualization"];

function Gas() {
    const { isLoggedIn } = useAuth();
    const [showMapModal, setShowMapModal] = useState(false);

    return (
        <>
            <Helmet>
                <title>Find Tax-Free Gas Stations | Support Indigenous Businesses | RezBuddy</title>
                <meta
                    name="description"
                    content="Find tax-free gas and the cheapest gas prices near you while supporting Indigenous businesses. Use this First Nation fuel finder to locate the best gas stations and fuel prices across Canada."
                />
                <meta
                    name="keywords"
                    content="tax-free gas, gas prices, Indigenous gas stations, First Nation fuel finder, cheapest gas, support Indigenous business, Canada, fuel finder"
                />
                <meta name="author" content="RezBuddy.ca" />

                <meta
                    property="og:title"
                    content="Find Tax-Free Gas Stations | Support Indigenous Businesses"
                />
                <meta
                    property="og:description"
                    content="Use RezBuddy to locate tax-free gas stations and find the cheapest gas prices near you, all while supporting Indigenous businesses."
                />
                <meta property="og:url" content="https://www.rezbuddy.ca/gas" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary" />
                <meta
                    name="twitter:title"
                    content="Find Tax-Free Gas Stations | Support Indigenous Businesses"
                />
                <meta
                    name="twitter:description"
                    content="Find tax-free gas and the best fuel prices while supporting Indigenous businesses in Canada with RezBuddy's fuel finder tool."
                />

                <link rel="canonical" href="https://www.rezbuddy.ca/gas" />
            </Helmet>
            <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                libraries={["places", "visualization"]}
            >
                <main className="main">
                    <Search setShowMapModal={setShowMapModal} />
                    <Hero />
                    {!isLoggedIn && (
                        <div className="login-false">Login to save favourites here</div>
                    )}
                    <PriceCards />
                    <About />
                    {showMapModal && <Mapbox setShowMapModal={setShowMapModal} />}
                </main>
            </APIProvider>
        </>
    );
}

export default Gas;
