import Homepage from "./Pages/Homepage/Homepage";
import Header from "./Components/Header/Header";
import { LocationProvider } from "./Components/LocationContext/LocationContext";
import { AuthProvider } from "./Components/LoginContext/LoginContext";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import VerifyEmail from "./Pages/VerifyUser/VerifyUser";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import Gas from "./Pages/Gas/Gas";
import Businesses from "./Pages/Businesses/Businesses";
import ScrollToTop from "./Components/Hooks/ScrollToTop";
import Details from "./Pages/Details/Details";
import Contact from "./Pages/Contact/Contact";
import { ModalProvider } from "./Components/ModalContext/ModalContext";
import SigninModal from "./Components/SigninModal/SigninModal";
import Privacy from "./Pages/Privacy/Privacy";
import Tos from "./Pages/Tos/Tos";
import NotFoundPage from "./Pages/NotFoundPage/NotFoundPage";
import UpdateBiz from "./Pages/UpdateBiz/UpdateBiz";
import Articles from "./Pages/Articles/Articles";
import EditArticles from "./Pages/EditArticles/EditArticles";
import ArticleFull from "./Pages/ArticleFull/ArticleFull";

function App() {
    return (
        <section className="background">
            <ModalProvider>
                <BrowserRouter>
                    <LocationProvider>
                        <AuthProvider>
                            <Header />
                            <SigninModal />
                            <ScrollToTop />
                            <Routes>
                                <Route path="/" element={<Homepage />} />
                                <Route path="/verify-email" element={<VerifyEmail />} />
                                <Route path="/forgot" element={<ResetPassword />} />
                                <Route path="/gas" element={<Gas />} />
                                <Route path="/indigenous-businesses" element={<Businesses />} />
                                <Route
                                    path="/indigenous-businesses/businesses/:letter"
                                    element={<Businesses />}
                                />
                                <Route
                                    path="/indigenous-businesses/category/:category"
                                    element={<Businesses />}
                                />
                                <Route path="/indigenous-businesses/:id" element={<Details />} />
                                <Route path="/contact-us" element={<Contact />} />
                                <Route path="/articles" element={<Articles />} />
                                <Route path="/edit-article" element={<EditArticles />} />
                                <Route path="/articles/:slug" element={<ArticleFull />} />
                                <Route path="/privacy-policy" element={<Privacy />} />
                                <Route path="/terms-of-service" element={<Tos />} />
                                <Route path="/info-update" element={<UpdateBiz />} />
                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                            <Footer />
                        </AuthProvider>
                    </LocationProvider>
                </BrowserRouter>
            </ModalProvider>
        </section>
    );
}

export default App;
